const defaultKeywords = [
  "photography",
  "sam benjamin",
  "leuven",
  "family photography",
  "portrait photography",
  "vlaams-brabant",
  "product photography",
];
export default defaultKeywords;
